<template>
  <ion-page>
    <ion-header>
      <ion-grid fixed>
        <ion-toolbar>
          <ion-buttons slot="start" v-if="isModal">
            <!-- Mainly for jobEX online consultation -->
            <ion-button slot="icon-only" @click="closeModal()"><ion-icon :icon="arrowBack"></ion-icon></ion-button>
          </ion-buttons>

          <ion-title><ion-label class="ion-text-wrap">Book an appointment</ion-label></ion-title>

          <ion-title slot="end" class="ion-no-padding ion-text-right" style="flex: initial; padding-right: 3px">
            <logo-img></logo-img>
          </ion-title>
        </ion-toolbar>
      </ion-grid>
    </ion-header>
    <ion-content :fullscreen="true">
      <!-- Loading Data -->
      <div class="spin" v-if="loading">
        <ion-spinner></ion-spinner>
      </div>

      <ion-grid fixed v-else>
        <form id="booking-form" @submit.prevent="submitBooking()" v-if="section == 1">
          <!-- Booking Items -->
          <div>
            <ion-select fill="outline" label="Please choose*" label-placement="floating" interface="popover" v-model="booking.bookingItemId"
                        :disabled="bookingItemId != null">
              <ion-select-option v-for="item in bookingItems" :key="item.id" :value="item.id">
                [{{ item.durationMinutes }} mins] {{ item.title }}
              </ion-select-option>
            </ion-select>
            <input class="hidden-input"  type="text" v-model="booking.bookingItemId" required />
          </div>

          <div v-show="booking.bookingItemId">
            <!-- Booking Slots -->
            <div v-if="filteredBookingSlots().length > 0">
              <ion-select fill="outline" label="Select a time*" label-placement="floating" interface="popover" v-model="booking.bookingSlotId">
                <ion-select-option v-for="slot in filteredBookingSlots()" :key="slot.id" :value="slot.id">
                  {{ formatSlotTime(slot) }}
                </ion-select-option>
              </ion-select>
              <input class="hidden-input" type="text" v-model="booking.bookingSlotId" required />
            </div>

            <!-- Your name -->
            <ion-input fill="outline" label="Your full name*" label-placement="floating"
                        v-model="booking.fullName" enterkeyhint="next" required></ion-input>

            <!-- Preferred name -->
            <ion-input fill="outline" label="Your preferred name" label-placement="floating"
                        v-model="booking.preferredName" enterkeyhint="next"></ion-input>

            <!-- Email Address -->
            <ion-input fill="outline" label="Your email*" label-placement="floating"
                      inputmode="email" type="email" v-model="booking.email" enterkeyhint="next" required></ion-input>

            <!-- Mobile Number -->
            <ion-input fill="outline" label="Your HK mobile number*" label-placement="floating"
                      inputmode="numeric" v-model="booking.phone" maxlength="8" required
                      helper-text="We may send you updates about the appointment via WhatsApp."></ion-input>

            <!-- Year of Study -->
            <ion-select fill="outline" label="Year of study*" label-placement="floating" interface="popover" v-model="booking.yearOfStudy">
              <ion-select-option v-for="year in OPTIONS.yearOfStudy" :key="year" :value="year">
                {{ year }}
              </ion-select-option>
            </ion-select>

            <ion-button expand="block" type="submit" shape="round">
              Book it
            </ion-button>
          </div>
        </form>
        
        <ion-row class="ion-justify-content-center" v-if="section == 2">
          <ion-col size="12">
            <ion-card class="ion-text-center">
              <ion-card-header v-if="booking.status == 'success'">
                <ion-icon color="success" class="icon-xxl" :icon="checkmarkCircle"></ion-icon>
                <ion-card-title>Thank you for your submission.</ion-card-title>
                <ion-card-subtitle>You will soon receive a confirmation email about your booking.</ion-card-subtitle>
              </ion-card-header>
              <ion-card-header v-else>
                <ion-icon color="warning" class="icon-xxl" :icon="alertCircleOutline"></ion-icon>
                <ion-card-title>Thank you for your submission.</ion-card-title>
                <ion-card-subtitle>The time of your booking is not yet confirmed. We will follow-up with you soon by email.</ion-card-subtitle>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// vue
import { computed, onMounted, reactive, ref, watch } from 'vue';

// icons
import { close, checkmarkCircle, alertCircleOutline, arrowBack, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonSpinner,
        IonButtons, IonButton, IonIcon, IonBackButton, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
        IonItem, IonLabel, IonList, IonListHeader, IonInput, IonTextarea, IonSelect, IonSelectOption,
        loadingController, toastController, onIonViewDidEnter } from '@ionic/vue';

// services
import BookingService from '@/services/BookingService';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import { utils } from '@/composables/utils';

export default {
  name: 'BookingPage',
  props: ["isModal", "bookingItemId"],
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonSpinner,
                IonButtons, IonButton, IonIcon, IonBackButton, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
                IonItem, IonLabel, IonList, IonListHeader, IonInput, IonTextarea, IonSelect, IonSelectOption, },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const { presentToast, formatDate, presentAlert, presentPrompt, closeModal, } = utils();
    const { itemId } = route.params;

    // state variables
    const user = computed(() => store.state.user);
    const loading = ref(false);
    const section = ref(1);
    const bookingItems = ref([]);
    const bookingSlots = ref([]);
    const booking = reactive({
      fullName: "",
      preferredName: "",
      email: "",
      phone: "",
      yearOfStudy: "",
      bookingItemId: props.bookingItemId || itemId || "",
      bookingSlotId: "",
      status: "",
      waGroupId: "",
      userId: "",
    });
    const filteredBookingSlots = () => {
      const bookingItem = bookingItems.value.find(bi => bi.id == booking.bookingItemId) || {};
      return bookingSlots.value.filter(bs => bs.durationMinutes == bookingItem.durationMinutes);
    }
    const formatSlotTime = (slot) => {
      return `${formatDate(slot.startTime, 'YYYY MMM DD (ddd) HH:mm')}-${formatDate(slot.endTime, 'HH:mm')}`;
    }

    // INIT: retrieve latest data
    const retrieveBookingData = () => {
      loading.value = true;
      BookingService.getBookingData().then(res => {
        loading.value = false;
        bookingItems.value = res.bookingItems;
        bookingSlots.value = res.bookingSlots;
      })
    }
    retrieveBookingData();

    const syncWithUserData = () => {
      const { id, fullName, waGroupId, preferredName, email, phone, yearOfStudy } = user.value;
      booking.fullName = fullName;
      booking.waGroupId = waGroupId;
      booking.preferredName = preferredName;
      booking.phone = phone;
      booking.email = email;
      booking.yearOfStudy = yearOfStudy;
      booking.userId = id;
    }

    syncWithUserData();

    watch(user, syncWithUserData);
    
    return {
      // icons
      close, checkmarkCircle, alertCircleOutline, arrowBack,
      
      // variables
      loading,
      booking, section,
      bookingItems, bookingSlots,
      OPTIONS: {
        yearOfStudy: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5', 'Others', 'Alumni'],
      },

      // methods
      t, formatDate, closeModal,
      submitBooking: async () => {
        const bookingSlot = bookingSlots.value.find(bs => bs.id == booking.bookingSlotId) || {};
        const bookingItem = bookingItems.value.find(bi => bi.id == booking.bookingItemId) || {};
        let description = bookingSlot.id ? `<b>${formatSlotTime(bookingSlot)}</b><br />` : '';
        description += `${bookingItem.title}`;
        description += `<br /><br />Full name: ${booking.fullName}`;
        if (booking.preferredName) description += `<br />Preferred name: ${booking.preferredName}`;
        description += `<br />Email: ${booking.email}`;
        description += `<br />Phone: ${booking.phone}`;
        if (booking.yearOfStudy) description += `<br />Year of study: ${booking.yearOfStudy}`;

        presentPrompt(`Confirm booking?<br /><br />${description}`, async () => {
          const loading = await loadingController.create({});
          await loading.present();

          // create new booking record
          const res = await BookingService.createNewBooking(booking, bookingItem, bookingSlot);

          // handle failure case
          if (['success', 'pending'].includes(res)) {
            booking.status = res;
            section.value = 2; // go to thank you section
          } else {
            retrieveBookingData();
            presentAlert("Sorry, the time you chose is no longer available. Please choose another timeslot.");
          }
          loading.dismiss();
        });
      },
      filteredBookingSlots,
      formatSlotTime,
    }
  },
}
</script>

<style scoped>
  #booking-form * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .hidden-input {
    opacity: 0;
    height: 0.01px;
    margin-top: -20px;
    position: absolute;
  }
</style>